<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="3">
        <Select
          size="small"
          v-model="query.taskitemType"
          :clearable="true"
          placeholder="选择任务类型"
        >
          <Option
            v-for="item in taskitemTypeArray"
            :key="'type_' + item.value"
            :value="item.value"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <DatePicker
          transfer
          size="small"
          class="text-black"
          v-model="actionDateRange"
          format="yyyy-MM-dd"
          type="daterange"
          style="width: 100%;"
          placeholder="选择作业日期"
          @on-change="onChangeActionDate"
        ></DatePicker>
      </i-col>
      <i-col span="2">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          @click="onSearchInfo"
        >搜索</Button>
      </i-col>
    </Row>
    <div class="text-right m-b-10">
      <span class="p-r-5">剩余未质检数量</span><span>{{ total }}</span>
    </div>
    <Row :gutter="8">
      <i-col span="11">
        <div
          class="m-b-10"
          style="padding: 10px 10px; background-color:#413f3f;"
        >
          <Row
            :gutter="8"
            class="m-b-10"
          >
            <i-col span="12">
              <label class="title">资源编号</label><span>{{ info.resourceCode||'/' }}</span>
            </i-col>
            <i-col span="12">
              <label class="title">作业日期</label><span>{{ info.actionDate }}</span>
            </i-col>
          </Row>
          <Row
            :gutter="8"
            class="m-b-10"
          >
            <i-col span="12">
              <label class="title">单品</label><span>{{  info.skuId > -1 ? info.skuName : '/' }}</span>
            </i-col>
            <i-col span="12">
              <label class="title">作业类型</label><span>{{ info.taskitemTypeName }}</span>
            </i-col>
          </Row>
          <Row :gutter="8">
            <i-col span="12">
              <label class="title">备注</label><span class="p-r-10">{{ info.remark }}</span><span>{{ info.extra }}</span>
            </i-col>
            <i-col span="12">
              <label class="title">重拍状态</label><span v-if="info.rephoto===0">无</span><span v-else-if="info.rephoto===1">重拍中</span><span v-else>重拍完成</span>
            </i-col>
          </Row>
        </div>
        <div>
          <div
            class="text-center"
            v-if="info.taskitemFiles&&info.taskitemFiles.length"
          >
            <template v-if="info.taskitemFiles[0].mimeType.includes('video')||info.taskitemFiles[0].mimeType.includes('audio')">
              <video
                id="ooh_video"
                :src="info.taskitemFiles[0].fileKey"
                height="600"
                width="100%"
                controls="controls"
              >
                该浏览器不支持视频播放
              </video>
            </template>
            <template v-else>
              <img
                :src="info.taskitemFiles[0].fileKey+'?x-oss-process=image/resize,w_680'"
                height="600"
                width="100%"
              />
            </template>
          </div>
          <div
            class="text-center"
            v-else
          >
            <h2>暂无数据</h2>
          </div>
        </div>

      </i-col>
      <i-col span="11">
        <Carousel
          v-if="info.taskitemAttachs&&info.taskitemAttachs.length"
          v-model="fileIndex"
          dots="outside"
          loop
        >
          <CarouselItem
            v-for="item in info.taskitemAttachs"
            :key="'attach_'+item.id"
          >
            <div class="demo-carousel">
              <template v-if="item.mimeType.includes('video')||item.mimeType.includes('audio')">
                <video
                  id="ooh_video"
                  :src="item.fileKey"
                  height="700"
                  width="100%"
                  controls="controls"
                >
                  该浏览器不支持视频播放
                </video>
              </template>
              <template v-else>
                <img
                  :src="item.fileKey+'?x-oss-process=image/resize,w_680'"
                  height="700"
                  width="100%"
                />
              </template>
            </div>
          </CarouselItem>
        </Carousel>
        <div
          class="text-center"
          v-else
        >
          <h2>暂无数据</h2>
        </div>
      </i-col>
      <i-col span="2">
        <div class="btn-group">
          <div class="opt-btn m-b-5">
            <Button
              type="success"
              @click="onConfirmCheck"
            >通过质检</Button>
          </div>
          <div class="opt-btn m-b-5">
            <Button
              type="warning"
              :disabled="btnRephoto"
              @click="onRephoto"
            >重拍</Button>
          </div>
          <div class="opt-btn m-b-5">
            <Button
              type="primary"
              :disabled="btnSkip"
              @click="onSkip"
            >跳过</Button>
          </div>
        </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { indexMixins } from '../../mixins'
import { optionMixins } from '../../mixins/option'
import { getQualityTaskitemPage } from '@/api/msp/plan'
import { confirmTaskFinish } from '@/api/msp/taskitem'
export default {
  mixins: [indexMixins, optionMixins],
  data () {
    return {
      actionDateRange: [],
      query: {
        pageNumber: 1,
        pageSize: 1,
        qualityTesting: 0,
        startActionDate: '',
        endActionDate: '',
        assetId: undefined,
        stationId: undefined,
        taskitemType: undefined,
        adType: undefined,
        keyword: ''
      },
      total: 0,
      info: {},
      fileIndex: 0
    }
  },
  computed: {
    btnRephoto () {
      if (this.info.rephoto > 0 && this.info.taskitemAttachs && this.info.taskitemAttachs.length) {
        return true
      } else {
        return false
      }
    },
    btnSkip () {
      return this.query.pageNumber === this.total
    }
  },
  methods: {
    initData () {
      this.query.taskitemType = undefined
      this.query.pageNumber = 1
      this.query.startActionDate = ''
      this.query.endActionDate = ''
      this.getTaskitemTypeData()
      this.getInfoData()
    },
    async getInfoData () {
      const result = await getQualityTaskitemPage(this.query).finally(() => { this.fileIndex = 0 })
      if (result && !result.errcode) {
        this.total = result.totalRow
        this.info = result.list && result.list.length ? result.list[0] : {}
        this.curTaskitemIds = [this.info.id]
      } else {
        this.total = 0
        this.info = {}
        this.curTaskitemIds = []
      }
    },
    onSearchInfo () {
      this.query.pageNumber = 1
      this.getInfoData()
    },
    /**
     * 通过质检
     */
    onConfirmCheck () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并通过质检？',
        onOk: () => {
          const postData = {
            taskitemIds: JSON.stringify(this.curTaskitemIds),
            produceTaskitemIds: ''
          }
          confirmTaskFinish(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.curTaskitemIds = []
              this.onSkip()
            }
          })
        }
      })
    },
    /**
     * 跳过
     */
    onSkip () {
      this.query.pageNumber++
      this.getInfoData()
    },
    updateTable () {
      this.getInfoData()
    }
  }
}
</script>
<style lang="less" scoped>
.btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}
.opt-btn {
  width: 100%;
  height: 33%;
  button {
    width: 80px;
    height: 100%;
  }
}
.demo-carousel {
  width: 100%;
  height: 700px;
  text-align: center;
  background-color: #bebec0;
}
</style>
